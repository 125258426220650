import axios from 'axios'
import { message, } from 'ant-design-vue'
import store from '@/store'
import '@/utils/lang'
const L = window.L;

// if (process.env.NODE_ENV === 'production') {
//     axios.defaults.baseURL = '/api'
// }
const instance = axios.create({
})


instance.interceptors.response.use(function (response) {

    const { status, data = {} } = response;

    if (status === 200) {
        if (data.status === 400) {
            store.commit('login/setVisible', true)
        } else if (data.status !== 1) {
            message.error(data.info ? L(data.info) : L('异常错误'))
        }
    }

    let result = {}

    if (typeof data.info === 'string') {
        result.info = data.info;
    } else if (Array.isArray(data.info)) {
        result.list = data.info;
    } else if (data.info && typeof data.info === 'object') {
        result = {...data.info}
    } 
    return {
        ...result,
        _status: data.status,
    };

}, function (error) {
    const { data = {} } = error.response || {};
    message.error(data.info ? L(data.info) : L('异常错误'))
    return Promise.reject(error)
})

function request({ url, method, headers, params, data }) {

    return new Promise((resolve, reject) => {

        instance({
            url, method, headers: {
                ...headers,
                token: window.localStorage.getItem('token')
            }, params, data
        }).then(res => {
            resolve(res)
        }).catch(reject)

    })


}

export default request;